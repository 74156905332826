<template>
  <div v-if="renderContent.length">
    <div class="titleRow inline-block">Избранное</div>
    <PosterSwiper
      :posters="renderContent"
      local-storage-key="favoritesList"
    />
  </div>
</template>

<script setup>
import PosterSwiper from '@/components/ui/Swipers/PosterSwiper.vue';

const channelsStore = useChannelsListStore();
const favoritesStore = useFavoritesListStore();
const { favoritesList } = storeToRefs(favoritesStore);

favoritesStore.fetchFavorites();
channelsStore.fetchChannels();

const favoriteFilmsAndPrograms = computed(() => favoritesList.value.filter(item => item.fav_type !== 'channel'));
const { user } = storeToRefs(useUserStore());
const show_unsubscribed = computed(() => Number(user.value?.current_profile?.show_unsubscribed) === 1);

const renderContent = computed(() => {
  return favoriteFilmsAndPrograms.value.filter(video => {
    if (video.fav_type === 'program') {
      const channel = channelsStore.currentChannel(video.channel_id);
      return !(!channel.id || (!show_unsubscribed.value && channel.locked));
    }
    return true;
  });
});
</script>
